
import { useContext } from 'react'
import { QueueContextProps, QueueManagementContext } from '../context/serviceLocationContext'

export const useServiceLocationContext = (): QueueContextProps => {
  const context = useContext(QueueManagementContext)
  if (!context) throw new Error('Error on load service location context!')

  return context
}
