import { Box, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import useStyles from "./style";

const Header = () => {
  const classes = useStyles();
  const unitData = localStorage.getItem("gov_ticket_office");
  const unidade = unitData ? JSON.parse(unitData) : null;
  const [horaAtual, setHoraAtual] = useState(new Date());

  useEffect(() => {
    const intervalo = setInterval(() => {
      setHoraAtual(new Date());
    }, 1000);

    return () => clearInterval(intervalo);
  }, []);

  const formatarDataHora = (data) => {
    const hora = new Intl.DateTimeFormat("pt-BR", {
      timeZone: "America/Campo_Grande",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(data);

    const dataFormatada = new Intl.DateTimeFormat("pt-BR", {
      timeZone: "America/Campo_Grande",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(data);

    return `${hora} ${dataFormatada}`;
  };

  return (
    <Box className={classes.main}>
      <Typography className={classes.title}>
        {unidade?.setor ? unidade?.setor?.unidade?.nome : unidade?.unidade?.nome}{"  "}
        {formatarDataHora(horaAtual)}
      </Typography>
    </Box>
  );
};

export default Header;
