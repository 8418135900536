import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { isBefore, isSameDay, parseISO } from 'date-fns';

import React, { useCallback, useState } from "react";
import { Atendimento } from "../../../../../interfaces/atendimento";
import AttendanceNewScheduleInternal from "../internal";
import Reschedule from "../reschedule";
import useStyles from "./style";

interface ModalScheduleProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  attendence: undefined | Atendimento;
}

const ModalSchedule: React.FC<ModalScheduleProps> = ({ openModal, setOpenModal, attendence }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const today = new Date();

  const isBeforeDate =  attendence.data && (isSameDay(parseISO(attendence.data), today) || !isBefore(parseISO(attendence.data), today)) 

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Agendamentos
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Status tabs"
          style={{
            borderBottom: "1px solid #F7F8FC",
            borderRadius: 4,
            marginLeft: 5,
          }}
        >
          <Tab
            label={
              <span className={classes.tabLabel}>
                Agendamento interno
              </span>
            }
          />
          {attendence.data && isBeforeDate &&
            <Tab
              label={
                <span className={classes.tabLabel}>
                  Reagendar
                </span>
              }
            />}
        </Tabs>

        {value === 0 && (
          <div className={classes.tabContent}>
            <AttendanceNewScheduleInternal attendence={attendence} setOpenModal={setOpenModal} />
          </div>
        )}

        {value === 1 && attendence.data && isBeforeDate &&
          <div className={classes.tabContent}>
            <Reschedule service={attendence} setOpenModal={setOpenModal}/>
          </div>
        }
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ModalSchedule);
