import { Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  title: {
    padding: '18px 0px 18px 34px',
    fontSize: '20px',
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
