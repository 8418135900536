import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  textModalTitleUDependent: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: 18,
    letterSpacing: 0.25,
  },
  textModalTitleUDependentCPF: {
    color: "rgba(55, 63, 65, 0.6)",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: 16,
  },
  textHeaderModal: {
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.3,
  },
  header: {
    background: "#FFF",
    border: "1px solid #F7F8FC",
    padding: 10
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#B7B7B7",
  },
  textModal: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: 16,
  },
  titleDependents: {
    display: "inline-grid",
    verticalAlign: "middle",
    margin: "0px 20px 29px",
  },
  textModalTitle: {
    fontSize: 14,
    color: "rgba(55, 63, 65, 0.6)",
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  link: {
    color: "#373F41",
    cursor: "pointer",
    fontSize: 14,
    fontFamily: "Mulish",
    fontWeight: "bold",
    textTransform: "uppercase",
    textDecorationLine: "underline",
    letterSpacing: 0.3,
    fontStyle: "normal",
    marginLeft: 16,
  },
  boxConcludedMain: {
      backgroundColor: "#373F41",
    },
    boxInfoConcluded: {
      margin: '10px 0px',
      borderRadius: 4,
      padding: '16px 24px',
      border: '1px solid #0BB07B',
      backgroundColor: 'rgba(11, 176, 123, 0.05)',
    },
    infoConcluded: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
     textInfoDateTime: {
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginBottom: 2,
    },
    textInfoUnity: {
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginTop: 16,
      marginBottom: 8,
    },
  menuItem: {
    color: theme.palette.primary.main,
  },
  box: {
      borderRadius: 4,
      border: `1px solid `,
      margin: '20px 32px',
    },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    // margin: theme.spacing(1),
    width: "100%",
  },
  titleBox: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      textTransform: 'uppercase',
      margin: '2px 0px 30px 0px',
    },
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
  },
  adornedEnd: {
    paddingRight: 0,
  },
  errorMessage: {
    color: "#FF0000",
    fontSize: 12,
    fontFamily: "Mulish",
    fontWeight: 400,
    marginTop: 4,
  },
  buttonCloseModal: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px 20px 0px",
    alignItems: "center"
  },
  buttonSave: {
    fontSize: 14,
    fontFamily: 'Mulish',
    textTransform: 'uppercase',
    marginTop: 16,
    borderRadius: 4
  }
}));

export default useStyles;
