import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  ArrowDropDown,
  ArrowRight,
  CheckCircleOutline,
  Menu,
  People,
  PlayArrow,
  Search,
  SyncAltRounded,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./style";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import PaginationService from "../../Components/PaginationService";
import { AgendamentoInterno } from "../../interfaces/agendamentoInterno";
import { getAgendamentosInternos } from "../../services/agendamentoInterno";
import { cpfMask } from "../../utils/cpfFormat";
import dateFormat, {
  extractDateFormat,
  extractHourFormat,
} from "../../utils/dateFormat";
import {
  AttendanceDialog,
  AttendanceDialogCancel,
  DependentsDialog,
} from "./components";
import AttendanceDialogStop from "./components/AttendanceDialogCancelStop";
import { StyledTableCell, StyledTableRow } from "./style";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SchedulesInternal(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [loadingCurrentNext, setLoadingCurrentNext] = useState<boolean>(false);
  const [loadingPrevious, setloadingPrevious] = useState<boolean>(false);

  const history = useHistory();

  const [currentSchedule, setCurrentSchedule] = useState<
    undefined | AgendamentoInterno
  >();

  const [selectedDate, handleDateChange] = useState<undefined | Date>(
    new Date()
  );

  const [countPageCurrentAttendance, setCountPageCurrentAttendance] =
    useState<number>(0);
  const [nextPageCurrentAttendance, setNextPageCurrentAttendance] =
    useState<number>(0);
  const [previousPageCurrentAttendance, setPreviousPageCurrentAttendance] =
    useState<number>(0);
  const [currentPageCurrentAttendance, setCurrentPageCurrentAttendance] =
    useState<number>(0);
  const [totalPagesCurrentAttendance, setTotalPagesCurrentAttendance] =
    useState<number>(0);

  const [nextSchedules, setNextSchedules] = useState<
    undefined | AgendamentoInterno[]
  >();

  const [schedulesDependentCurrent, setSchedulesDependentCurrent] = useState<
    undefined | AgendamentoInterno
  >();

  const [previousSchedules, setPreviousSchedules] = useState<
    undefined | AgendamentoInterno[]
  >();

  const [countPagePreviousAttendance, setCountPagePreviousAttendance] =
    useState<number>(0);
  const [nextPagePreviousAttendance, setNextPagePreviousAttendance] =
    useState<number>(0);
  const [previousPagePreviousAttendance, setPreviousPagePreviousAttendance] =
    useState<number>(0);
  const [currentPagePreviousAttendance, setCurrentPagePreviousAttendance] =
    useState<number>(0);
  const [totalPagesPreviousAttendance, setTotalPagesPreviousAttendance] =
    useState<number>(0);

  const [filter, setFilter] = useState<undefined | "CPF" | "Data">(undefined);
  const [valueFilter, setValueFilter] = useState<undefined | string>();
  const [showDetails, setShowDetails] = useState<undefined | string>();


  async function agendamentosDaVez() {
    try {
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche_id = JSON.parse(localStorage.getItem("gov_ticket_office") || "").id;

      setLoadingCurrentNext(true);
      const { data } = await getAgendamentosInternos(token, {
        guiche_id,
        hoje: true,
        page: 1,
        in_status: 'Aguardando,Compareceu,Em Atendimento'
      });

      if (data.results.length) {
        const current = data.results;
        setCurrentSchedule(current.splice(0, 1)[0]);
      } else {
        setCurrentSchedule(undefined);
      }
    } catch (error) {
    } finally {
      setLoadingCurrentNext(false);
    }
  }

  async function agendamentosHoje(page = 1) {
    try {
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche_id = JSON.parse(localStorage.getItem("gov_ticket_office") || "").id;

      setLoadingCurrentNext(true);
      const { data } = await getAgendamentosInternos(token, {
        guiche_id,
        hoje: true,
        page,
        in_status: 'Aguardando,Compareceu,Em Atendimento'
      });

      setCountPageCurrentAttendance(data.count);
      setNextPageCurrentAttendance(data.next);
      setPreviousPageCurrentAttendance(data.previous);
      setCurrentPageCurrentAttendance(data.current);
      setTotalPagesCurrentAttendance(data.total_pages);
      if (data.results.length) {
        const current = data.results;
        if (page === 1) {
          current?.shift();

          setNextSchedules(current);
        } else {
          setNextSchedules(current);
        }
      } else {
        setCurrentSchedule(undefined);
        setNextSchedules(undefined);
      }
    } catch (error) {
    } finally {
      setLoadingCurrentNext(false);
    }
  }

  async function agendamentosAnteriores(page = 1) {
    try {
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche_id = JSON.parse(localStorage.getItem("gov_ticket_office") || "").id;

      setloadingPrevious(true);
      let params: any = {
        anteriores: true,
        page,
        guiche_id,
        out_status: 'Aguardando,Em Atendimento,Compareceu'
      };

      if (filter === "Data" && selectedDate) {
        params["data"] = format(selectedDate, "yyyy-MM-dd");
      }

      if (filter === "CPF") {
        params["cpf"] = valueFilter.replaceAll(/[^\w\s]/gi, "");
      }
      const { data } = await getAgendamentosInternos(token, params);
      setCountPagePreviousAttendance(data.count);
      setNextPagePreviousAttendance(data.next);
      setPreviousPagePreviousAttendance(data.previous);
      setCurrentPagePreviousAttendance(data.current);
      setTotalPagesPreviousAttendance(data.total_pages);
      if (data.results) {
        const previousSchedulesLines = data.results;
        setPreviousSchedules(previousSchedulesLines);
      }
    } catch (error) {
    } finally {
      setloadingPrevious(false);
    }
  }

  useEffect(() => {
    agendamentosHoje();
    agendamentosAnteriores();
    agendamentosDaVez();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openAttendanceCancel, setOpenAttendanceCancel] =
    useState<boolean>(false);

  const [openAttendanceDialog, setOpenAttendanceDialog] =
    useState<boolean>(false);

  const [openDependentsDialog, setOpenDependentsDialog] =
    useState<boolean>(false);

  const [openAttendanceStop, setOpenAttendanceStop] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
    handleCloseMenu();
  };
  const matchesMobile = useMediaQuery("(min-width:576px)");
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null);

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: "menu"
  ) => {
    if (type === "menu") {
      setAnchorElMenu(event.currentTarget);
    }
  };

  const searchUSer = () => {
    agendamentosAnteriores();
  };

  const changeAutoComplete = (event: any, valueParam: any): void => {

    if (valueParam === null) {
      history.go(0);
    }
    setFilter(valueParam);

  };
  return (
    <Box className={classes.main}>
      <Box
        style={{
          overflow: "auto",
        }}
      >
        <Paper className={classes.root}>
          <Box className={classes.tableDatail}>Agendamento da vez</Box>
          <Box style={{ overflow: "auto" }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>CIDADÃO</StyledTableCell>
                  <StyledTableCell align="left">CPF</StyledTableCell>
                  <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                  <StyledTableCell align="left">STATUS</StyledTableCell>
                  <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                  <StyledTableCell align="left">AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingCurrentNext && <CircularProgress />}
                {currentSchedule && (
                  <Fragment key={currentSchedule.cpf}>
                    <StyledTableRow
                      style={{ backgroundColor: "#FFF" }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {showDetails &&
                          showDetails === currentSchedule.ticket ? (
                          <Button
                            onClick={() => {
                              setShowDetails(undefined);
                            }}
                          >
                            <ArrowDropDown />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setShowDetails(currentSchedule.ticket);
                            }}
                          >
                            <ArrowRight />
                          </Button>
                        )}
                        {currentSchedule.user.first_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {cpfMask(currentSchedule.cpf)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {`${dateFormat(currentSchedule.data)} ${currentSchedule.hora
                          }`}
                      </StyledTableCell>
                      <StyledTableCell className={classes.status} align="left">
                        {currentSchedule.status}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currentSchedule.servico.titulo}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currentSchedule.dependentes_interno && (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.buttonIcon}
                            onClick={() => {
                              setSchedulesDependentCurrent(currentSchedule);
                              setOpenDependentsDialog(true);
                            }}
                            title="Dependentes"
                          >
                            <People />
                          </Button>
                        )}
                        {!currentSchedule.dependentes_interno && (
                          <span title="Sem dependentes">
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              disabled
                            >
                              <People />
                            </Button>
                          </span>
                        )}
                        {(currentSchedule.status === "Aguardando" || currentSchedule.status === "Compareceu") && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.buttonIcon}
                              onClick={() => setOpenAttendanceDialog(true)}
                              title="Iniciar atendimento"
                            >
                              <PlayArrow />
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.buttonIconAttention}
                              onClick={() => setOpenAttendanceCancel(true)}
                              title="Não compareceu"
                            >
                              <Icon className="material-icons-outlined">
                                person_off
                              </Icon>
                            </Button>
                          </>
                        )}

                        {currentSchedule.status === "Em Atendimento" && (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              onClick={() => setOpenAttendanceStop(true)}
                              title="Finalizar atendimento"
                            >
                              <CheckCircleOutline />
                            </Button>
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                    {showDetails &&
                      showDetails === currentSchedule.ticket && (
                        <StyledTableRow
                          key={currentSchedule.ticket}
                          style={{
                            borderTopStyle: "dotted",
                            borderTopWidth: 1.4,
                          }}
                        >
                          <StyledTableCell>
                            <Typography
                              className={classes.textTitleDetails}
                              style={{ paddingLeft: 60 }}
                            >
                              INICIO DO ATENDIMENTO
                            </Typography>
                            <Typography
                              className={classes.textValueDetails}
                              style={{ paddingLeft: 60 }}
                            >
                              {currentSchedule.status ===
                                "Não Compareceu"
                                ? "Não Compareceu"
                                : `${currentSchedule.hora_inicio ? `${extractDateFormat(
                                  currentSchedule.hora_inicio
                                )} às ${extractHourFormat(
                                  currentSchedule.hora_inicio
                                )}` : 'Não iniciado'}`}
                            </Typography>


                          </StyledTableCell>

                          <StyledTableCell>
                            <Typography
                              className={classes.textTitleDetails}
                            >
                              PROTOCOLO AGENDAMENTO
                            </Typography>
                            <Typography
                              className={classes.textValueDetails}
                            >
                              {currentSchedule?.protocolo || ''}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell colSpan={4}>
                            <Typography
                              className={classes.textTitleDetails}
                            >
                              PROTOCOLO ATENDIMENTO
                            </Typography>
                            <Typography
                              className={classes.textValueDetails}
                            >
                              {currentSchedule?.protocolo_atendimento || 'Sem protocolo de atendimento'}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </Box>

          <DependentsDialog
            setOpenDependentsDialog={setOpenDependentsDialog}
            openDependentsDialog={openDependentsDialog}
            schedule={schedulesDependentCurrent}
          />
          <AttendanceDialog
            setOpenAttendanceDialog={setOpenAttendanceDialog}
            openAttendanceDialog={openAttendanceDialog}
            schedule={currentSchedule}
          />
          <AttendanceDialogCancel
            setOpenAttendanceCancel={setOpenAttendanceCancel}
            openAttendanceCancel={openAttendanceCancel}
            schedule={currentSchedule}
          />
          <AttendanceDialogStop
            setOpenAttendanceStop={setOpenAttendanceStop}
            openAttendanceStop={openAttendanceStop}
            schedule={currentSchedule}
          />
        </Paper>
      </Box>
      <Typography className={classes.textSchedules}>
        Lista de agendamentos
      </Typography>

      <Box
        style={{
          background: "#FFF",
          padding: 5,
        }}
      >
        {matchesMobile ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
            style={{
              borderBottom: "1px solid #F7F8FC",
              borderRadius: 4,
              marginLeft: 24,
            }}
          >
            <Tab
              style={{ padding: 0, marginRight: 48 }}
              label={
                <span className={classes.tabLabel}>
                  Próximos agendamentos hoje (
                  {countPageCurrentAttendance && countPageCurrentAttendance - 1}
                  )
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  Agendamentos Anteriores ({countPagePreviousAttendance || 0})
                </span>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        ) : (
          <>
            <IconButton onClick={(e): void => handleClick(e, "menu")}>
              <Menu />
            </IconButton>
            {value === 0 &&
              ` Próximo agendamentos hoje (
                  ${countPageCurrentAttendance && countPageCurrentAttendance - 1
              }
                  )`}
            {value === 1 &&
              `Agendamentos Anteriores (${countPagePreviousAttendance || 0})`}
            <Popover
              open={Boolean(anchorElMenu)}
              anchorEl={anchorElMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box>
                <Divider />

                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(0)}
                >
                  <Typography className={classes.textMenuItem}>
                    Próximos agendamentos hoje (
                    {countPageCurrentAttendance &&
                      countPageCurrentAttendance - 1}
                    )
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(1)}
                >
                  <Typography className={classes.textMenuItem}>
                    Agendamentos Anteriores ({countPagePreviousAttendance || 0})
                  </Typography>
                </MenuItem>
              </Box>
            </Popover>
          </>
        )}

        <Box>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box style={{ overflow: "auto" }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left" colSpan={2}>SERVIÇO</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingCurrentNext && <CircularProgress />}
                  {nextSchedules &&
                    nextSchedules.map((rowsSchedulesToday) => (
                      <Fragment key={rowsSchedulesToday.ticket}>
                        <StyledTableRow key={rowsSchedulesToday.ticket}>
                          <StyledTableCell component="th" scope="row">
                            {showDetails &&
                              showDetails === rowsSchedulesToday.ticket ? (
                              <Button
                                onClick={() => {
                                  setShowDetails(undefined);
                                }}
                              >
                                <ArrowDropDown />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  setShowDetails(rowsSchedulesToday.ticket);
                                }}
                              >
                                <ArrowRight />
                              </Button>
                            )}
                            {rowsSchedulesToday.user.first_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {cpfMask(rowsSchedulesToday.cpf)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {`${dateFormat(rowsSchedulesToday.data)} ${rowsSchedulesToday.hora
                              }`}
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.status}
                            align="left"
                          >
                            {rowsSchedulesToday.status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesToday.servico.titulo}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesToday.dependentes_interno && (
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonIcon}
                                onClick={() => {
                                  setSchedulesDependentCurrent(rowsSchedulesToday);
                                  setOpenDependentsDialog(true);
                                }}
                                title="Dependentes"
                              >
                                <People />
                              </Button>

                            )}

                          </StyledTableCell>
                        </StyledTableRow>
                        {showDetails &&
                          showDetails === rowsSchedulesToday.ticket && (
                            <StyledTableRow
                              key={rowsSchedulesToday.ticket}
                              style={{
                                borderTopStyle: "dotted",
                                borderTopWidth: 1.4,
                              }}
                            >
                              <StyledTableCell colSpan={6}>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  PROTOCOLO AGENDAMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {rowsSchedulesToday?.protocolo || ''}

                                </Typography>
                              </StyledTableCell>

                            </StyledTableRow>
                          )}
                      </Fragment>
                    ))}
                  {!nextSchedules && !loadingCurrentNext && (
                    <h2>Nenhum agendamento para hoje</h2>
                  )}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={agendamentosHoje}
              listSearch={{
                count: countPageCurrentAttendance,
                next: nextPageCurrentAttendance,
                previous: previousPageCurrentAttendance,
                current: currentPageCurrentAttendance,
                total_pages: totalPagesCurrentAttendance,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ width: "100%" }}
            >
              <Autocomplete
                id="controllable-states-demo"
                options={["Data", "CPF"]}
                value={filter}
                onChange={changeAutoComplete}
                style={{
                  width: !matchesMobile ? "100%" : 162,
                  marginBottom: 15,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filtrar por"
                  />
                )}
              />
              {matchesMobile && (
                <IconButton>
                  <SyncAltRounded />
                </IconButton>
              )}
              {filter && filter === "Data" && (
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    invalidDateMessage="A data informada é inválida"
                    InputProps={{
                      className: classes.menuItem,
                      classes: { adornedEnd: classes.adornedEnd },
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Selecione o dia"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "end", color: "#000" }}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        handleDateChange(date);
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
              {filter && filter === "CPF" && (
                <>
                  <InputMask
                    value={valueFilter}
                    onChange={(event): void => {
                      if (event.target.value) {
                        setValueFilter(event.target.value);
                      }
                    }}
                    mask="999.999.999-99"
                    disabled={filter === undefined}
                  >
                    {() => (
                      <TextField
                        placeholder="Ex: 000.000.000-00"
                        disabled={filter === undefined}
                        style={{
                          width: !matchesMobile ? "100%" : 235,
                          marginBottom: 15,
                          marginRight: matchesMobile ? 15 : 0,
                        }}
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </>
              )}
              {(!filter) && (
                <Autocomplete
                  id="controllable-states-demo"
                  options={[{ name: "" }]}
                  getOptionLabel={(filter) => filter.name}
                  style={{
                    width: !matchesMobile ? "100%" : 235,
                    marginBottom: 15,
                    marginRight: matchesMobile ? 15 : 0,
                  }}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="-------------"
                    />
                  )}
                />
              )}
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.buttonSearch}
                style={!matchesMobile ? { width: "100%", margin: 0 } : {}}
                onClick={searchUSer}
                startIcon={<Search />}
              >
                BUSCAR
              </Button>
            </Box>
            <Box style={{ overflow: "auto" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="25%">NOME</StyledTableCell>
                    <StyledTableCell align="left">CPF</StyledTableCell>
                    <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                    <StyledTableCell align="left">STATUS</StyledTableCell>
                    <StyledTableCell align="left" colSpan={2}>SERVIÇO</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingPrevious && <CircularProgress />}
                  {previousSchedules &&
                    previousSchedules.map((rowsSchedulesPreviou) => (
                      <Fragment key={rowsSchedulesPreviou.ticket}>
                        <StyledTableRow
                          style={{
                            borderBottomStyle: "none",
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {showDetails &&
                              showDetails === rowsSchedulesPreviou.ticket ? (
                              <Button
                                onClick={() => {
                                  setShowDetails(undefined);
                                }}
                              >
                                <ArrowDropDown />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  setShowDetails(rowsSchedulesPreviou.ticket);
                                }}
                              >
                                <ArrowRight />
                              </Button>
                            )}

                            {rowsSchedulesPreviou.user.first_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {cpfMask(rowsSchedulesPreviou.cpf)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {`${dateFormat(rowsSchedulesPreviou.data)} ${rowsSchedulesPreviou.hora
                              }`}
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.status}
                            align="left"
                          >
                            {rowsSchedulesPreviou.status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.servico.titulo}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {rowsSchedulesPreviou.dependentes_interno && (<Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              onClick={() => {
                                setSchedulesDependentCurrent(rowsSchedulesPreviou);
                                setOpenDependentsDialog(true);
                              }}
                              title="Dependentes"
                            >
                              <People />
                            </Button>)}

                          </StyledTableCell>
                        </StyledTableRow>

                        {showDetails &&
                          showDetails === rowsSchedulesPreviou.ticket && (
                            <StyledTableRow
                              key={rowsSchedulesPreviou.ticket}
                              style={{
                                borderTopStyle: "dotted",
                                borderTopWidth: 1.4,
                              }}
                            >
                              <StyledTableCell>
                                <Typography
                                  className={classes.textTitleDetails}
                                  style={{ paddingLeft: 60 }}
                                >
                                  INICIO DO ATENDIMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                  style={{ paddingLeft: 60 }}
                                >
                                  {rowsSchedulesPreviou.status ===
                                    "Não Compareceu"
                                    ? "Não Compareceu"
                                    : `${rowsSchedulesPreviou.hora_inicio &&
                                    extractDateFormat(
                                      rowsSchedulesPreviou.hora_inicio
                                    )
                                    } às ${rowsSchedulesPreviou.hora_inicio &&
                                    extractHourFormat(
                                      rowsSchedulesPreviou.hora_inicio
                                    )
                                    }`}
                                </Typography>


                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  FIM DO ATENDIMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {rowsSchedulesPreviou.status ===
                                    "Não Compareceu"
                                    ? "Não Compareceu"
                                    : `${extractDateFormat(
                                      rowsSchedulesPreviou.updated_at
                                    )} às ${extractHourFormat(
                                      rowsSchedulesPreviou.updated_at
                                    )}`}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell colSpan={2}>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  OBSERVAÇÕES:
                                </Typography>
                                {rowsSchedulesPreviou?.observacao_interna
                                  .length ? (
                                  rowsSchedulesPreviou?.observacao_interna.map(
                                    (observacao) => (
                                      <Typography
                                        className={classes.textValueDetails}
                                      >
                                        {`(${extractDateFormat(
                                          observacao.updated_at
                                        )} às ${extractHourFormat(
                                          observacao.updated_at
                                        )})  - (${observacao?.user?.first_name} - ${cpfMask(observacao?.user?.username)}) - ${observacao.conteudo} `}
                                      </Typography>
                                    )
                                  )
                                ) : (
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    Sem Observações
                                  </Typography>
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  PROTOCOLO AGENDAMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {rowsSchedulesPreviou?.protocolo || ''}

                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.textTitleDetails}
                                >
                                  PROTOCOLO ATENDIMENTO
                                </Typography>
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {rowsSchedulesPreviou?.protocolo_atendimento || ''}

                                </Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <PaginationService
              getSearch={agendamentosAnteriores}
              listSearch={{
                count: countPagePreviousAttendance,
                next: nextPagePreviousAttendance,
                previous: previousPagePreviousAttendance,
                current: currentPagePreviousAttendance,
                total_pages: totalPagesPreviousAttendance,
              }}
            />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}
