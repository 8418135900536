import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  getUserData as getUserDataRequest,
  logout,
} from "../../services/auth";
import useStyles from "./style";
import { useStateValue } from "../../providers/StateProvider";
import { actionTypes } from "../../store/reducer";
import { getGuiche } from "../../services/giche";

export default function InitPage(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const [{ user, currentTicketOffice }, dispatch] = useStateValue();

  const [ticketOffices, setTicketOffices] = useState([]);

  const [ticketOfficeSelected, setTicketOfficeSelected] = useState<string>();

  const token: string | null = localStorage.getItem("gov_access_token_sso");
  const tokenSiseci: string | null = localStorage.getItem(
    "gov_access_token_sso"
  );

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      dispatch({
        type: actionTypes.SET_USER,
        user: data,
      });
      localStorage.setItem("gov_user_data", JSON.stringify(data));
    } catch (err) {

      console.log(err);
      logout();
    }
  };

  const handleSelectTicketOffice = async () => {
    if (ticketOfficeSelected) {
      const ticketOffice = ticketOffices.find((ticketOff) => ticketOff.id === parseInt(ticketOfficeSelected));
      if (ticketOffice) {
        localStorage.setItem("gov_ticket_office", JSON.stringify(ticketOffice));
        dispatch({
          type: actionTypes.SET_TICKET_OFFICE,
          currentTicketOffice: ticketOffice,
        });
        history.push("/home");
      }
    }
  }

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requestCurrentTicketOffice = async () => {
      try {
        const { data } = await getGuiche(tokenSiseci);
        if (data?.length) {
          setTicketOffices(data);
        } else {
          await logout();
        }
      } catch (error) {
        console.log(error);
      } finally {

      }
    };
    const ticket = localStorage.getItem("gov_ticket_office");
    if (user && !ticket) {
      requestCurrentTicketOffice();
    } else if (ticket) {
      dispatch({
        type: actionTypes.SET_TICKET_OFFICE,
        currentTicketOffice: JSON.parse(ticket),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const ticketOffice = localStorage.getItem("gov_ticket_office");
    if ((user && currentTicketOffice && ticketOffice)) {
      const pathname = localStorage.getItem("pathname_redirect_gov");
      const spathname = pathname === "/" ? "/home" : pathname;
      history.push(spathname || "/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentTicketOffice]);

  return (
    <Box className={classes.main}>
      {ticketOffices.length > 0 ? (<>
        <Typography variant="h5" className={classes.text}>
          Selecione o guichê
        </Typography>
        <Box>
          <select onChange={(e) => setTicketOfficeSelected(e.target.value)} className={classes.selectTicket}>
            <option value={null}> Selecione </option>
            {ticketOffices.map(ticket => (
              <option value={ticket?.id}>
                {`GUICHÊ ${ticket?.ordem} :
                ${ticket?.nome || ""}
                ${ticket?.setor?.setor?.nome || 'Misto'} - ${ticket?.tipo === "Diario" ? "Orgânico" : ticket?.tipo === "Interno" ? "Complementar" : ticket?.tipo} -
            ${ticket?.setor?.unidade?.nome || ticket?.unidade?.nome || ''}`}
              </option>))}
          </select>

        </Box>
        {ticketOfficeSelected && (<Box>
          <button className={classes.btnSelectTicket} onClick={handleSelectTicketOffice}>Selecionar</button>
        </Box>)}
      </>) : (<>
        <img
          src="/assets/images/SupportAgent.svg"
          className={classes.icon}
          alt="logo Atendimento"
        />
        <Typography className={classes.text}>Atendimento</Typography>
        <CircularProgress className={classes.loading} />
      </>)}

    </Box>
  );
}
