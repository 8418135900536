import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    color: theme.palette.primary.main,
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#d3d3d3',
      },
    },
  },
  textModalTitleUDependent: {
    color: '#373F41',
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0.25,
  },
  textModalTitleUDependentCPF: {
    color: 'rgba(55, 63, 65, 0.6)',
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: 16,
  },
  textHeaderModal: {
    textTransform: 'uppercase',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    width: '98%',
  },
  header: {
    background: '#FFF',
    border: '1px solid #F7F8FC',
  },
  buttonClose: {
    color: '#B7B7B7',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700,
  },
  textModal: {
    color: '#373F41',
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: 16,
  },
  titleDependents: {
    display: 'inline-grid',
    verticalAlign: 'middle',
    margin: '15px 20px 29px',
  },
  textModalTitle: {
    fontSize: 14,
    color: 'rgba(55, 63, 65, 0.6)',
    textTransform: 'uppercase',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  buttonCloseModal: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 0px 20px',
    alignItems: 'center',
  },
  link: {
    color: '#373F41',
    cursor: 'pointer',
    fontSize: 15,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textDecorationLine: 'none',
    letterSpacing: 0.3,
    fontStyle: 'normal',
    marginLeft: 16,
  },
  buttonSecondary: {
    maxWidth: 112,
    height: 48,
    borderRadius: 4,
    backgroundColor: '#94C120',
    color: '#ffffff',
  },
  headerModal: {
    borderBottom: '4px solid #F7F8FC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 32px',
  },
  titleModal: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#373F41',
  },
  contentModal: {
    margin: '30px 0px 50px 0px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 21,
  },
  boxService: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
  },
  titleBolder: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    color: '#373F41',
    textTransform: 'uppercase',
  },
  cpfText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    color: 'rgba(55, 63, 65, 0.6)',
  },
  titleService: {
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(55, 63, 65, 0.6)',
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  footerModal: {
    borderTop: '4px solid #F7F8FC',
    padding: '16px 24px',
  },
  icon: {
    width: 53,
    height: 53,
    color: '#94C120',
  },
  boxLoadingIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  linkTo: {
    marginTop: 8,
    fontSize: 14,
    color: 'rgba(55, 63, 65, 0.6)',
  },
  textError: {
    color: '#F03D3D',
    fontSize: 14,
    fontWeight: 400,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  textLabel: {
    color: '#6B6D6E',
  },
}));

export default useStyles;
