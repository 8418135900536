import DateFnsUtils from "@date-io/date-fns";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  AddCircle,
  ArrowDropDown,
  ArrowRight,
  CancelRounded,
  Menu,
  MoreVert,
  Search,
  Today
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, isSameDay, isValid, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { Fragment, useEffect, useState } from "react";
import { MdSearchOff } from "react-icons/md";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import PaginationService from "../../Components/PaginationService";
import { Agendamento, AgendamentoDiario } from "../../interfaces/agendamento";
import { AgendamentoInterno } from "../../interfaces/agendamentoInterno";
import { ES } from "../../interfaces/es";
import { useStateValue } from "../../providers/StateProvider";
import { searchAgendamentos } from "../../services/agendamento";
import { searchAgendamentosInternos } from "../../services/agendamentoInterno";
import { searchAgendamentosDiarios } from "../../services/agendamentoOrganico";
import { getUnidadeServices } from "../../services/servicos";
import { cpfMask } from "../../utils/cpfFormat";
import dateFormat, { extractDateFormat, extractHourFormat } from "../../utils/dateFormat";
import DialogAttend from "./components/DialogAttend";
import DialogCancel from "./components/DialogCancel";
import DialogNotAttend from "./components/DialogNotAttend";
import ReScheduler from "./components/ReScheduler";
import SchedulerDialog from "./components/SchedulerDialog";
import { CustomChip, StyledTableCell, StyledTableRow, useStyles } from "./style";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export interface ServiceResults {
  id: number;
  requisitos: string;
  setor: number;
  slug: string
  titulo: string;
}
export interface Service extends ES {
  results: ServiceResults[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SearchSchedules(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [{ currentTicketOffice }] = useStateValue();
  const [value, setValue] = useState(0);
  const [loadingSchedule, setLoadingSchedule] = useState<boolean>(false);
  const [ScheduleOrganicShow, setScheduleOrganicShow] = useState<boolean>(false);
  const [servicesData, setServicesData] = useState<Service>();
  const [loadingScheduleInternal, setLoadingScheduleInternal] =
    useState<boolean>(false);
  const [loadingScheduleDaily, setLoadingScheduleDaily] =
    useState<boolean>(false);
  const [openNotAttend, setOpenNotAttend] = useState(false);
  const [openAttend, setOpenAttend] = useState(false);
  const [openReScheduleModal, setOpenReScheduleModal] = useState(false);
  const [openAttendanceCancel, setOpenAttendanceCancel] = useState(false);
  const matchesMobile = useMediaQuery("(max-width:555px)");

  const [currentSchedule, setCurrentSchedule] = useState<Agendamento>();

  const [currentTypeSchedule, setCurrentTypeSchedule] = useState<string>();

  const [schedule, setSchedule] = useState<undefined | Agendamento[]>();
  const [scheduleInternal, setScheduleInternal] = useState<
    undefined | AgendamentoInterno[]
  >();
  const [scheduleDaily, setScheduleDaily] = useState<
    undefined | AgendamentoDiario[]
  >();

  const [valueFilterCpf, setValueFilterCpf] = useState<undefined | string>();
  const [valueFilterStatus, setValueFilterStatus] = useState<
    undefined | string
  >(undefined);
  const [showDetails, setShowDetails] = useState<undefined | string>();
  const [listSearchSchedule, setListSearchSchedule] = useState<
    ES | undefined
  >();
  const [listSearchScheduleInternal, setListSearchScheduleInternal] = useState<
    ES | undefined
  >();
  const [listSearchScheduleDaily, setListSearchScheduleDaily] = useState<
    ES | undefined
  >();
  const [selectedDate, handleDateChange] = useState<null | Date>(null);
  const matchesMd = useMediaQuery("(min-width:1184px)");
  const today = new Date();

  async function agendamentos(page = 1) {
    try {
      setLoadingSchedule(true);
      setSchedule(undefined);
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      let params: any = {
        page,
      };
      if (selectedDate) {
        params["data"] = format(selectedDate, "yyyy-MM-dd");
      }
      if (valueFilterCpf && valueFilterCpf.replace(/[_.-]/g, "")) {
        params["cpf"] = valueFilterCpf.replace(/[_.-]/g, "");
      }
      if (valueFilterStatus) {
        params["status"] = valueFilterStatus;
      }

      const { data } = await searchAgendamentos(token || '', params);
      if (data) {
        setSchedule(data.results);
        setListSearchSchedule({
          count: data.count,
          next: data.next,
          previous: data.previous,
          current: data.current,
          total_pages: data.total_pages,
        });
      }
    } catch (error) {
    } finally {
      setLoadingSchedule(false);
    }
  }

  async function agendamentosInternos(page = 1) {
    try {
      setScheduleInternal(undefined);
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );

      let params: any = {
        page,
      };

      if (selectedDate) {
        params["data"] = format(selectedDate, "yyyy-MM-dd");
      }
      if (valueFilterCpf && valueFilterCpf.replace(/[_.-]/g, "")) {
        params["cpf"] = valueFilterCpf.replace(/[_.-]/g, "");
      }
      if (valueFilterStatus) {
        params["status"] = valueFilterStatus;
      }
      setLoadingScheduleInternal(true);
      const { data } = await searchAgendamentosInternos(token || '', params);
      if (data) {
        setScheduleInternal(data.results);
        setListSearchScheduleInternal({
          count: data.count,
          next: data.next,
          previous: data.previous,
          current: data.current,
          total_pages: data.total_pages,
        });
      }
    } catch (error) {
    } finally {
      setLoadingScheduleInternal(false);
    }
  }

  async function agendamentosDiarios(page = 1) {
    try {
      setScheduleDaily(undefined);
      setLoadingScheduleDaily(true);
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );

      let params: any = {
        page,
        exibir_fila: true
      };

      if (selectedDate) {
        params["data"] = format(selectedDate, "yyyy-MM-dd");
      }
      if (valueFilterCpf && valueFilterCpf.replace(/[_.-]/g, "")) {
        params["cpf"] = valueFilterCpf.replace(/[_.-]/g, "");
      }
      if (valueFilterStatus) {
        params["status"] = valueFilterStatus;
      }

      const { data } = await searchAgendamentosDiarios(token || '', params);
      if (data) {
        setScheduleDaily(data.results);
        setListSearchScheduleDaily({
          count: data.count,
          next: data.next,
          previous: data.previous,
          current: data.current,
          total_pages: data.total_pages,
        });
      }
    } catch (error) {
    } finally {
      setLoadingScheduleDaily(false);
    }
  }

  const handleOpenNotAttend = () => {
    setOpenNotAttend(true);
    handleCloseMenuOpton();

  };

  const handleOpenReScheduleModal = () => {
    setOpenReScheduleModal(true);
    handleCloseMenuOpton();
  };

  const handleAttend = () => {
    setOpenAttend(true);
    handleCloseMenuOpton();
  };

  const handleOpenCancel = () => {
    setOpenAttendanceCancel(true);
    handleCloseMenuOpton();
  };
  const handleUpdateList = () => {
    agendamentos();
    agendamentosInternos();
    agendamentosDiarios();
  };

  useEffect(() => {
    if (currentTicketOffice) {
      agendamentos();
      agendamentosInternos();
      agendamentosDiarios();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicketOffice]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
    handleCloseMenu();
  };

  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null);

  const [anchorElOption, setAnchorElOption] =
    React.useState<HTMLButtonElement | null>(null);

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleCloseMenuOpton = () => {
    setAnchorElOption(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: "menu"
  ) => {
    if (type === "menu") {
      setAnchorElMenu(event.currentTarget);
    }
  };

  const handleOpenOptions = (
    event: React.MouseEvent<HTMLButtonElement>,
    schedule: any,
    type: string
  ) => {
    setCurrentTypeSchedule(type);
    setCurrentSchedule(schedule);
    setAnchorElOption(event.currentTarget);
  };

  const getServices = async () => {
    const slug_unidade = currentTicketOffice?.setor?.unidade?.slug_unidade || currentTicketOffice?.unidade?.slug_unidade;
    const { data } = await getUnidadeServices(slug_unidade);
    const {
      total_pages, next,
    } = data;
    const lista: any[] = [];
    const loopServices = (pageApi: number):
      Promise<void> => new Promise((_resolve, _reject) => {
        if (pageApi > 0) {
          getUnidadeServices(slug_unidade, pageApi)
            .then((response) => {
              lista.push(...response?.data?.results);
              setServicesData({ ...data, results: lista });
            });
        }
      });
    const promises = [];
    if (total_pages > 1 && next !== null) {
      for (let index = 0; index <= total_pages; index += 1) {
        promises.push(loopServices(index));
      }
    } else {
      setServicesData(data);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Promise.all(promises).then((newData) => newData);
  };

  useEffect(() => {
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={classes.main}>
        <Popover
          open={Boolean(anchorElOption)}
          anchorEl={anchorElOption}
          onClose={handleCloseMenuOpton}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box className={classes.BoxMenuOptions}>
            {/* <MenuItem className={classes.itemMenuOption} onClick={handleAttend}>
            <Typography className={classes.textMenuOption}>
              <MdTaskAlt className={classes.iconMenuOption} /> COMPARECEU
            </Typography>
          </MenuItem>
            <MenuItem className={classes.itemMenuOption} onClick={handleOpenNotAttend}>
              <Typography className={classes.textMenuOption} >
              <Block className={classes.iconMenuOption} /> NÃO COMPARECEU
            </Typography>
          </MenuItem> */}
            <MenuItem className={classes.itemMenuOption} onClick={handleOpenCancel}>
              <Typography className={classes.textMenuOption}>
                <CancelRounded className={classes.iconMenuOption} /> CANCELAR
              </Typography>
            </MenuItem>
            <MenuItem disabled={!currentSchedule?.data || !isSameDay(parseISO(currentSchedule?.data), today)} className={classes.itemMenuOption} onClick={handleOpenReScheduleModal}>
              <Typography className={classes.textMenuOption}>
                <Today className={classes.iconMenuOption} /> REAGENDAR
              </Typography>
            </MenuItem>
          </Box>
        </Popover>

        <Box className={matchesMobile ? classes.headerTopAlt : classes.headerTop}>
          <Typography className={classes.textSchedules}>
            Lista de agendamentos
          </Typography>
          <Button
            className={classes.buttonAttendance}
            variant="contained"
            color="primary"
            onClick={() => setScheduleOrganicShow(true)}
          >
            <AddCircle />
            <span style={{ marginLeft: 10 }}>
              Gerar Atendimento
            </span>
          </Button>
        </Box>
        <Box
          style={{
            overflowX: "auto",
            overflowY: 'hidden',
            marginBottom: 25,
            width: "100%",
          }}
        >
          <Paper className={classes.root}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              flexWrap="wrap"
            >
              <>
                <MuiPickersUtilsProvider
                  locale={ptBR}
                  utils={DateFnsUtils}
                >
                  <KeyboardDatePicker
                    disableFuture={false}
                    minDateMessage=""
                    invalidDateMessage="A data informada é inválida"
                    InputProps={{
                      className: classes.menuItem,
                      classes: { adornedEnd: classes.adornedEnd },
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Data"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{
                      position: "end",
                      color: "#000",
                    }}
                    style={
                      !matchesMd
                        ? { width: "100%", marginBottom: 20 }
                        : { marginRight: 20 }
                    }
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        handleDateChange(date);
                      } else {
                        handleDateChange(null);
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
                <InputMask
                  value={valueFilterCpf}
                  onChange={(event: any): void => {
                    if (event?.target?.value) {
                      setValueFilterCpf(event.target.value);
                    }
                  }}
                  mask={"999.999.999-99"}
                >
                  {() => (
                    <TextField
                      placeholder="Ex: 000.000.000-00"
                      label="CPF"
                      style={
                        !matchesMd
                          ? { width: "100%", marginBottom: 20 }
                          : { marginRight: 20 }
                      }
                      variant="outlined"
                    />
                  )}
                </InputMask>
                <Autocomplete
                  id="controllable-states-demo"
                  value={valueFilterStatus}
                  options={[
                    "Aguardando Atendimento",
                    "Em Atendimento",
                    "Finalizado",
                    "Cancelado",
                    "Não Compareceu",
                    "Reagendou",
                    "Compareceu",
                  ]}
                  onChange={(event: any, newValue: string | null) => {
                    setValueFilterStatus(newValue as string);
                  }}
                  style={
                    !matchesMd
                      ? { width: "100%", marginBottom: 20 }
                      : { marginRight: 20, minWidth: 235 }
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Status"
                    />
                  )}
                />
              </>
              {(selectedDate ||
                valueFilterCpf ||
                valueFilterStatus) && (
                  <Button
                    variant="outlined"
                    size="large"
                    className={classes.buttonCancelSearch}
                    startIcon={<MdSearchOff />}
                    onClick={() => {
                      history.go(0);
                    }}
                  >
                    LIMPAR
                  </Button>
                )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonSearch}
                style={!matchesMd ? { width: "100%", margin: 0 } : {}}
                startIcon={<Search />}
                onClick={handleUpdateList}
              >
                BUSCAR
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box
          style={{
            overflowX: "auto",
            overflowY: 'hidden',
            background: "#FFF",
          }}
        >
          {matchesMd ? (
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs example"
              style={{
                marginLeft: 24,
              }}
            >
              <Tab
                style={{ padding: 0, marginRight: 10, minWidth: 160 }}
                label={
                  <div className={classes.tabLabel}>
                    <span style={{ marginRight: 25 }}>Presenciais</span>
                    <Badge
                      badgeContent={listSearchSchedule?.count || 0}
                      color="primary"
                    />
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <span className={classes.tabLabel}>
                    <span style={{ margin: 10 }}>Complementares</span>
                    <Badge badgeContent={listSearchScheduleInternal?.count || 0} color="primary" />
                  </span>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <div className={classes.tabLabel}>
                    <span style={{ marginRight: 25 }}>Orgânico</span>
                     <Badge badgeContent={listSearchScheduleDaily?.count || 0} color="primary" />
                  </div>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          ) : (
            <>
              <IconButton onClick={(e): void => handleClick(e, "menu")}>
                <Menu />
              </IconButton>
              {value === 0 &&
                `Presenciais (${listSearchSchedule
                  ? listSearchSchedule?.count
                  : 0
                } )`}
              {value === 1 &&
                `Complementares (${listSearchScheduleInternal
                  ? listSearchScheduleInternal?.count
                  : 0
                })`}
              {value === 2 &&
                `Espontâneo ( ${listSearchScheduleDaily
                  ? listSearchScheduleDaily?.count
                  : 0
                })`}
              <Popover
                open={Boolean(anchorElMenu)}
                anchorEl={anchorElMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box>
                  <Divider />

                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleChangeIndex(0)}
                  >
                    <Typography className={classes.textMenuItem}>
                      {`Presenciais (${listSearchSchedule
                        ? listSearchSchedule?.count
                        : 0
                        })`}
                    </Typography>
                  </MenuItem>
                  <Divider />

                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleChangeIndex(1)}
                  >
                    <Typography className={classes.textMenuItem}>
                      {`Complementares (${listSearchScheduleInternal
                        ? listSearchScheduleInternal?.count
                        : 0
                        })`}
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleChangeIndex(2)}
                  >
                    <Typography className={classes.textMenuItem}>
                      {`Orgânico (${listSearchScheduleDaily
                        ? listSearchScheduleDaily?.count
                        : 0
                        })`}
                    </Typography>
                  </MenuItem>
                </Box>
              </Popover>
            </>
          )}

          <Box>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box style={{
                overflowX: "auto",
                overflowY: 'hidden',
              }}>
                <Table
                  className={classes.table}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>NOME</StyledTableCell>
                      <StyledTableCell align="left">
                        CPF
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        DATA/HORA
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        STATUS
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        SERVIÇO
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        AÇÕES
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingSchedule && <CircularProgress />}
                    {schedule &&
                      schedule.map((rowsSchedule) => (
                        <Fragment key={rowsSchedule.ticket}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {showDetails &&
                                showDetails === rowsSchedule.ticket ? (
                                <Button
                                  onClick={() => {
                                    setShowDetails(undefined);
                                  }}
                                >
                                  <ArrowDropDown />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setShowDetails(rowsSchedule.ticket);
                                  }}
                                >
                                  <ArrowRight />
                                </Button>
                              )}
                              {rowsSchedule.user.first_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {cpfMask(rowsSchedule.cpf)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {`${dateFormat(rowsSchedule.data)} ${rowsSchedule.hora
                                }`}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.status}
                              align="left"
                            >
                              {rowsSchedule.status}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsSchedule.servico.titulo}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsSchedule.status === "Aguardando Atendimento" && <IconButton onClick={(e): void => handleOpenOptions(e, rowsSchedule, 'presencial')}>
                                <MoreVert color="primary" />
                              </IconButton>}
                            </StyledTableCell>
                          </StyledTableRow>
                          {showDetails &&
                            showDetails === rowsSchedule.ticket && (
                              <StyledTableRow
                                key={rowsSchedule.ticket}
                                style={{
                                  borderTopStyle: "dotted",
                                  borderTopWidth: 1.4,
                                }}
                              >
                                <StyledTableCell colSpan={3}>
                                  <Typography
                                    className={classes.textTitleDetails}
                                    style={{ paddingLeft: 60 }}
                                  >
                                    GUICHÊ:
                                    <span className={classes.textValueDetails}>
                                      {`${rowsSchedule?.guiche?.ordem || ""} - `}
                                      {rowsSchedule?.setor?.nome
                                        ? rowsSchedule?.setor?.nome : 'Geral'}
                                    </span>
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    PROTOCOLO AGENDAMENTO
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    {rowsSchedule?.protocolo || 'Sem protocolo de agendamento'}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    PROTOCOLO ATENDIMENTO
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    {rowsSchedule?.protocolo_atendimento || 'Sem protocolo de atendimento'}

                                  </Typography>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}

                        </Fragment>
                      ))}
                    {!schedule && !loadingSchedule && (
                      <h2>Nenhum agendamento encontrado</h2>
                    )}
                  </TableBody>
                </Table>
              </Box>
              {!loadingSchedule && (
                <PaginationService
                  getSearch={agendamentos}
                  listSearch={listSearchSchedule}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box style={{
                overflowX: "auto",
                overflowY: 'hidden',
              }}>
                <Table
                  className={classes.table}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="25%">
                        NOME
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        CPF
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        DATA/HORA
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        STATUS
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        SERVIÇO
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingScheduleInternal && <CircularProgress />}
                    {scheduleInternal &&
                      scheduleInternal.map((rowsScheduleInternal) => (
                        <Fragment key={rowsScheduleInternal.ticket}>
                          <StyledTableRow
                            style={{
                              borderBottomStyle: "none",
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              {showDetails &&
                                showDetails ===
                                rowsScheduleInternal.ticket ? (
                                <Button
                                  onClick={() => {
                                    setShowDetails(undefined);
                                  }}
                                >
                                  <ArrowDropDown />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setShowDetails(
                                      rowsScheduleInternal.ticket
                                    );
                                  }}
                                >
                                  <ArrowRight />
                                </Button>
                              )}

                              {rowsScheduleInternal.user.first_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {cpfMask(rowsScheduleInternal.cpf)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {`${dateFormat(
                                rowsScheduleInternal.data
                              )} ${rowsScheduleInternal.hora}`}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.status}
                              align="left"
                            >
                              {rowsScheduleInternal.status}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsScheduleInternal.servico.titulo}
                            </StyledTableCell>
                          </StyledTableRow>

                          {showDetails &&
                            showDetails ===
                            rowsScheduleInternal.ticket && (
                              <StyledTableRow
                                key={rowsScheduleInternal.ticket}
                                style={{
                                  borderTopStyle: "dotted",
                                  borderTopWidth: 1.4,
                                }}
                              >
                                <StyledTableCell colSpan={2}>
                                  <Typography
                                    className={classes.textTitleDetails}
                                    style={{ paddingLeft: 60 }}
                                  >
                                    GUICHÊ:
                                    <span className={classes.textValueDetails}>
                                      {`${rowsScheduleInternal?.guiche?.ordem || ""} - `}
                                      {rowsScheduleInternal?.setor?.nome
                                        ? rowsScheduleInternal?.setor?.nome : 'Geral'}
                                    </span>
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    PROTOCOLO AGENDAMENTO
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    {rowsScheduleInternal?.protocolo || 'Sem protocolo de agendamento'}

                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    PROTOCOLO ATENDIMENTO
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                  >
                                    {rowsScheduleInternal?.protocolo_atendimento || 'Sem protocolo de atendimento'}

                                  </Typography>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                        </Fragment>
                      ))}
                    {!scheduleInternal && !loadingScheduleInternal && (
                      <h2>Nenhum agendamento encontrado</h2>
                    )}
                  </TableBody>
                </Table>
              </Box>
              {!loadingScheduleInternal && (
                <PaginationService
                  getSearch={agendamentosInternos}
                  listSearch={listSearchScheduleInternal}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Box style={{
                overflowX: "auto",
                overflowY: 'hidden',
              }}>
                <Table
                  className={classes.table}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        POSIÇÃO NA FILA
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        NOME
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        CPF
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        TIPO
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        SENHA
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        STATUS
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        SERVIÇO
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        AÇÕES
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingScheduleDaily && <CircularProgress />}
                    {scheduleDaily &&
                      scheduleDaily.map((rowsScheduleDaily, index) => (
                        <>
                          <StyledTableRow
                            key={rowsScheduleDaily.ticket}
                            style={{
                              borderBottomStyle: "none",
                              color: '#000'
                            }}
                          >
                            <StyledTableCell align="left">
                              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <Button
                                  className={classes.noPaddingMarginButton}
                                  onClick={() => setShowDetails(showDetails === rowsScheduleDaily.ticket ? undefined : rowsScheduleDaily?.ticket)}
                                >
                                  {showDetails === rowsScheduleDaily.ticket ? <ArrowDropDown /> : <ArrowRight />}
                                </Button>
                                <span style={{ color: "#000" }}>
                                  {rowsScheduleDaily?.status === "Aguardando Atendimento"
                                    ? `${(index + 1 + (listSearchScheduleDaily?.current - 1) * 10)}º`
                                    : '-'}
                                </span>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" >
                              {rowsScheduleDaily?.nome}
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ color: "#000" }}>
                              {cpfMask(rowsScheduleDaily.cpf)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {rowsScheduleDaily?.prioridade_atendimento?.nome ? <CustomChip
                                label={rowsScheduleDaily.prioridade_atendimento.nome}
                                color={rowsScheduleDaily.prioridade_atendimento.cor_fonte}
                                backgroundColor={rowsScheduleDaily.prioridade_atendimento.cor_tag}
                              /> : '-'}
                            </StyledTableCell>
                            <StyledTableCell align="center" color="#373F41" style={{ fontWeight: '600' }}>
                              {rowsScheduleDaily?.senha_formatada}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.status}
                              style={{ minWidth: '200px' }}
                              align="center"
                            >
                              {rowsScheduleDaily.status}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowsScheduleDaily.servico.titulo}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {(rowsScheduleDaily.status === "Aguardando Atendimento") && <IconButton onClick={(e): void => handleOpenOptions(e, rowsScheduleDaily, 'diario')}>
                                <MoreVert color="primary" />
                              </IconButton>}
                            </StyledTableCell>
                          </StyledTableRow>

                          {showDetails &&
                            showDetails ===
                            rowsScheduleDaily.ticket && (
                              <StyledTableRow
                                key={rowsScheduleDaily.ticket}
                                style={{
                                  borderTopStyle: "dotted",
                                  borderTopWidth: 1.4,
                                }}
                              >
                                <StyledTableCell colSpan={2}>
                                  <Typography
                                    className={classes.textTitleDetails}
                                    style={{ paddingLeft: 60, fontWeight: 'bold' }}
                                  >
                                    GUICHÊ:
                                    <span className={classes.textValueDetails} style={{ color: '#000' }}>
                                      {`${rowsScheduleDaily?.guiche?.ordem || ""} - `}
                                      {rowsScheduleDaily?.setor?.nome ? rowsScheduleDaily?.setor?.nome : 'Geral'}
                                    </span>
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell colSpan={6}>
                                  <Typography
                                    className={classes.textTitleDetails}
                                  >
                                    GERAÇÃO DA SENHA
                                  </Typography>
                                  <Typography
                                    className={classes.textValueDetails}
                                    style={{ color: '#000' }}
                                  >
                                    {`${extractDateFormat(
                                      rowsScheduleDaily.updated_at
                                    )}  ${extractHourFormat(
                                      rowsScheduleDaily.updated_at
                                    )}`}
                                  </Typography>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                        </>
                      ))}
                    {!scheduleDaily && !loadingScheduleDaily && (
                      <h2>Nenhum agendamento encontrado</h2>
                    )}
                  </TableBody>
                </Table>
              </Box>
              {!loadingScheduleDaily && (
                <PaginationService
                  getSearch={agendamentosDiarios}
                  listSearch={listSearchScheduleDaily}
                />
              )}
            </TabPanel>
          </Box>
        </Box>
      </Box>
      {openNotAttend && <DialogNotAttend
        setOpenAttendanceCancel={setOpenNotAttend}
        openAttendanceCancel={openNotAttend}
        schedule={currentSchedule}
      />}
      {openAttendanceCancel &&
        <DialogCancel
          setOpenAttendanceCancel={setOpenAttendanceCancel}
          openAttendanceCancel={openAttendanceCancel}
          schedule={currentSchedule}
          currentTypeSchedule={currentTypeSchedule}
        />}
      {openAttend && <DialogAttend
        setOpenAttendanceCancel={setOpenAttend}
        openAttendanceCancel={openAttend}
        schedule={currentSchedule}
      />}
      {openReScheduleModal && <ReScheduler
        openSchedulerDialog={openReScheduleModal}
        setOpenSchedulerDialog={setOpenReScheduleModal}
        schedule={currentSchedule}
        currentTypeSchedule={currentTypeSchedule}
      />}
      {ScheduleOrganicShow && <SchedulerDialog
        openSchedulerDialog={ScheduleOrganicShow}
        setOpenSchedulerDialog={setScheduleOrganicShow}
        service_data={servicesData as any}
        unidade_slug={currentTicketOffice?.setor?.unidade?.slug_unidade || currentTicketOffice?.unidade?.slug_unidade}
      />}
    </>
  );
}
