import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Agendamento } from "../../../../interfaces/agendamento";
import { updateStatusAgendamento } from "../../../../services/agendamento";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";

import { Alert } from "@material-ui/lab";
import { useStyles } from "./style";


interface Props {
  openAttendanceDialog: boolean;
  setOpenAttendanceDialog: (openAttendanceDialog: boolean) => void;
  schedule: undefined | Agendamento;
}

export default function AttendanceDialog({
  setOpenAttendanceDialog,
  openAttendanceDialog,
  schedule,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const matchesMobile = useMediaQuery("(min-width:576px)");
  const [status, setStatus] = useState<string>("");

  const handleInit = async (scheduleId) => {
    try {
      const token = localStorage.getItem("gov_access_token_sso");
      const guiche = JSON.parse(localStorage.getItem("gov_ticket_office") || "{}").id;
      setLoading(true);
      if (token) {
         await updateStatusAgendamento(
          token,
          scheduleId,
          "Em Atendimento",
          guiche
        );
        // setOpenAttendanceDialog(false);
        history.go(0);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setStatus(error.response.data.detail);
            setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        console.log("Error ao iniciar Atendimento:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpenAttendanceDialog(false);
  };

  return (
    <Dialog
      fullWidth
      open={openAttendanceDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja iniciar o atendimento?
        </Typography>
      </DialogTitle>
      {schedule && (
        <DialogContent>
          {status && (
            <Box mb={3}>
              <Alert severity="warning">{status}</Alert>
            </Box>
          )}
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule.user.first_name}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.cpf)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!matchesMobile && (
              <Button
                style={{ color: "#FFF", fontSize: 12, width: "100%" }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleInit(schedule.id)}
                disabled={loading || !!status}
              >
                {!loading ? "Iniciar atendimento" : <CircularProgress />}
              </Button>
            )}
            {!loading && (
              <Link
                className={classes.link}
                onClick={handleClose}
                style={
                  !matchesMobile
                    ? { width: "100%", textAlign: "center", padding: "15px 0" }
                    : {}
                }
              >
                fechar
              </Link>
            )}
            {matchesMobile && (
              <Button
                style={{ color: "#FFF", fontSize: 12 }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleInit(schedule.id)}
                disabled={loading || !!status}
              >
                {!loading ? "Iniciar atendimento" : <CircularProgress />} 
              </Button>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
