import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  textModalTitleUDependent: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: 18,
    letterSpacing: 0.25,
  },
  textModalTitleUDependentCPF: {
    color: "rgba(55, 63, 65, 0.6)",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: 16,
  },
  textHeaderModal: {
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.3,
  },
  header: {
    background: "#FFF",
    border: "1px solid #F7F8FC",
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#B7B7B7",
  },
  textModal: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: 16,
  },
  titleDependents: {
    display: "inline-grid",
    verticalAlign: "middle",
    margin: "15px 20px 29px",
  },
  textModalTitle: {
    fontSize: 14,
    color: "rgba(55, 63, 65, 0.6)",
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  buttonCloseModal: {
    display: "flex",
    padding: '32px 0px',
  },
  link: {
    color: '#373F41',
    cursor: 'pointer',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    textTransform: "uppercase",
    textDecorationLine: 'underline',
    letterSpacing: 0.3,
    fontStyle: "normal",
    marginLeft: 16
  },
}));

export default useStyles;
