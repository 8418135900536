import Badge from '@material-ui/core/Badge';
import NotificationsNoneRounded from '@material-ui/icons/NotificationsNoneRounded';

import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    minHeight: 50,
    boxShadow: "2px 2px 6px 1px rgba(0, 0, 0, 0.2)",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#FFFFFF",
    padding: 5,
    marginBottom: 40,
  },
  textHeader: {
    fontFamily: "Mulish",
    fontSize: 14,
    fontStyle: "normal",
    letterSpacing: 0.3,
    marginLeft: 40,
  },
  textName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  textCpf: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 300,
    textAlign: "start",
  },
  avatar: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    marginRight: 10,
    border: `1px solid #FFFFFF`,
  },
  menuItem: {
    minHeight: 42,
    color: "#737B7D",
    minWidth: 236,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "normal",
    marginLeft: 10,
  },
  textTicketOffice: {

    '@media (min-width: 576px)': {
      alignItems: 'center',
    },
  },
  userMenus: {
    flex: 0.2,
  },
}));

export const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#E15050',
    color: '#E15050',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: 30,
    marginRight: 5,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

export const SmallNotifications = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
  },
}))(NotificationsNoneRounded);

export default useStyles;