import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    overflow: 'hidden',
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
    },
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    zIndex: 1,
  },
  currentInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    alignItems: 'center',
    padding: '20px',
    transition: 'transform 0.5s ease-in-out, min-height 0.5s ease-in-out',
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 100px)',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '30px',
    },
  },
  zoomEffect: {
    transform: 'scale(1.1)',
    minHeight: '50vh',
    position: 'relative',
    zIndex: 2,
  },
  currentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  currentLabel: {
    fontWeight: 'normal',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
    },
  },
  currentNumber: {
    fontSize: '7rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '7rem',
    },
  },
  noInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '50px 0',
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    fontSize: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      margin: '20px 0', 
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '50px',
    },
  },
}));
