import { AxiosResponse } from 'axios';
import { apisocket } from './api';

export const healthCheck = (): Promise<AxiosResponse<string>> => {
  return apisocket.get('/health', {
    headers: {
      'Accept': 'application/json'
    }
  });
};

interface Payload {
  guiche: string;
  nome: string;
  senha: string;
}

interface SendMessageParams {
  token: string;
  room_id: string;
  payload: Payload;
}

export const sendMessage = ({
  token,
  room_id,
  payload
}: SendMessageParams): Promise<AxiosResponse> => {
  return apisocket.post(
    '/room/message',
    {
      room_id,
      payload
    },
    {
      headers: {
        'Accept': 'application/json',
        'Token': token,
        'Content-Type': 'application/json'
      }
    }
  );
};

export const closeMessage = ({
  token,
  room_id,
}: SendMessageParams): Promise<AxiosResponse> => {
  return apisocket.post(
    '/room/close',
    {
      room_id,
    },
    {
      headers: {
        'Accept': 'application/json',
        'Token': token,
        'Content-Type': 'application/json'
      }
    }
  );
};

interface CreateRoomParams {
  token: string;
  id: string;
}

export const createRoom = async ({ id, token }: CreateRoomParams): Promise<boolean> => {
  try {
    const response: AxiosResponse<string> = await apisocket.post(
      '/room',
      { id: id },
      { headers: { token: token } }
    );
    return response.status === 201;
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 422) {
        throw new Error('Sala já existe.');
      }
    }
    console.error('Erro ao criar a sala.');
  }
};
