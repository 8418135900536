import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Header from "./components/Header";
import ServicePanelInfo from "./components/panel";
import { QueueManagementProvider } from "./context/serviceLocationContext";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
  },
  dialogContentText: {
    fontSize: '1.1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'end',
    paddingBottom: theme.spacing(3),
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonSecondary: {
    color: theme.palette.text.secondary,
  },
}));

const ServicePanel = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const classes = useStyles();

  const handleFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
    setIsFullscreen(true);
    setShowModal(false);
  };

  const handleCancelFullscreen = () => {
    setShowModal(false);
  };

  return (
    <QueueManagementProvider>
      <Header />
      <ServicePanelInfo />

      <Dialog
        open={showModal}
        onClose={handleCancelFullscreen}
        aria-labelledby="fullscreen-dialog-title"
        aria-describedby="fullscreen-dialog-description"
      >
        <DialogTitle id="fullscreen-dialog-title" className={classes.dialogTitle}>
          Ativar Tela Cheia
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="fullscreen-dialog-description" className={classes.dialogContentText}>
            Para uma melhor experiência no painel de atendimentos, recomendamos ativar o modo de <b>tela cheia</b>. Deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCancelFullscreen} className={classes.buttonSecondary}>
            Não, Obrigado
          </Button>
          <Button onClick={handleFullscreen} className={classes.buttonPrimary} autoFocus>
            Sim, Ativar Tela Cheia
          </Button>
        </DialogActions>
      </Dialog>
    </QueueManagementProvider>
  );
};

export default ServicePanel;
