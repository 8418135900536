import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Divider,
  Link,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Atendimento } from "../../../../interfaces/atendimento";
import { updateStatusAtendimento, addObservationAttendence } from "../../../../services/atendimento";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";

import useStyles from "./style";

interface Props {
  openAttendanceCancel: boolean;
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void;
  attendence: undefined | Atendimento;
}


export default function AttendanceDialogCancel({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  attendence,
}: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [observation, setObservation] = useState(undefined);
  const history = useHistory();

  const handleClose = () => {
    setOpenAttendanceCancel(false);
  };

  const handleFinishAttendance = async (scheduleId) => {
    const token: string | null = localStorage.getItem(
      "gov_access_token_sso"
    );
    const guiche = JSON.parse(localStorage.getItem("gov_ticket_office") || "").id;

    try {
      setLoading(true);
      if (token) {
        const { status } = await updateStatusAtendimento(
          token,
          scheduleId,
          "Não Compareceu",
          guiche,
        );
        if (status === 200 && !!observation) {
          await addObservationAttendence(token, scheduleId, observation || "");
        }
        history.go(0);
      }
    } catch (error) {
      console.log("Erro", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja informar o não comparecimento do cidadão?
        </Typography>
      </DialogTitle>
      {attendence && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {attendence.user.first_name}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(attendence.user.username)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {attendence.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(attendence.data)} às ${attendence.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {attendence.status}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              observações:
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              disabled={loading}
              inputProps={{
                style: { fontSize: 14, fontFamily: "Mulish", fontWeight: 400 },
              }}
              style={{ width: "100%", marginBottom: 10 }}
              value={observation}
              onChange={(e) => {
                setObservation(e.target.value);
              }}
            />
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}
            <Button
              style={{ color: "#FFF", fontSize: 12, flex: 0.5 }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => handleFinishAttendance(attendence.id)}
              disabled={loading}
            >
              {!loading ? "CONFIRMAR" : <CircularProgress />}
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
