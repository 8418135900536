import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Divider,
  Link,
} from "@material-ui/core";
import { Close, People } from "@material-ui/icons";
import { AgendamentoInterno } from "../../../../interfaces/agendamentoInterno";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";

import useStyles from "./style";

interface Props {
  openDependentsDialog: boolean;
  setOpenDependentsDialog: (openDependentsDialog: boolean) => void;
  schedule: undefined | AgendamentoInterno;
}

export default function DependentsDialog({
  setOpenDependentsDialog,
  openDependentsDialog,
  schedule,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleClose = () => {
    setOpenDependentsDialog(false);
  };

  return (
    <Dialog
      fullWidth
      open={openDependentsDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>DEPENDENTE</Typography>
      </DialogTitle>
      {schedule && schedule.dependentes_interno && (
        <DialogContent>
          <People color="primary" fontSize="large" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule.dependentes_interno.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.dependentes_interno.cpf)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              Data de nascimento
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.dependentes_interno.data_nascimento)}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              grau de parentesco
            </Typography>
            <Typography className={classes.textModal}>
              {schedule.dependentes_interno.grau_parentesco}
            </Typography>
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            <Link className={classes.link} onClick={handleClose}>
              fechar
            </Link>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
