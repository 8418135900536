import React from 'react';
import {  Route, RouteProps, useLocation } from 'react-router-dom';
import { StateProvider } from '../../providers/StateProvider';
import { loginRedirectPrivider } from "../../services/auth";
import reducer, { initialState } from '../../store/reducer';

interface Props extends RouteProps {
  layout: React.ComponentType<any>;
  component: React.ComponentType<any>;
  isAuth?: boolean;
}

const RouteLayout = (props: Props): JSX.Element => {
  const location = useLocation();
  const token: string | null = localStorage.getItem('gov_access_token_sso');
  const {
    layout: Layout, component: Component, path, isAuth,
  } = props;
  if (isAuth && !token) {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  }
  return (
    <Route
      path={path}
      render={(matchProps) => (
        <StateProvider initialState={initialState} reducer={reducer}>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </StateProvider>
      )}
    />
  );
};

export default RouteLayout;
