import { Box, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../../providers/StateProvider";
import { useStyles } from "./style";

export default function Home(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [{ user, currentTicketOffice }] = useStateValue();
  return (
    <>
      <Box className={classes.main}>
        <Typography className={classes.textTitleHome}>
          {user && `Bem vindo(a), ${user?.nome}.`}
        </Typography>
        <img
          src="/assets/images/logo_home.gif"
          alt="Home"
          className={classes.imgHome}
        />
        <Box>
          {currentTicketOffice?.tipo === "Interno" && (
            <Button
              variant="contained"
              className={classes.buttonLink}
              onClick={() => history.push("/agendamento_interno")}
            >
              Atendimento complementar
            </Button>
          )}

          {currentTicketOffice?.tipo === "Consulta" && (
            <Button
              variant="contained"
              className={classes.buttonLink}
              onClick={() => history.push("/consulta_agendamento")}
            >
              Buscar agendamentos
            </Button>
          )}
          {currentTicketOffice?.tipo === "Presencial" && (
            <Button
              variant="contained"
              className={classes.buttonLink}
              onClick={() => history.push("/agendamento")}
            >
              atendimento
            </Button>
          )}
          {currentTicketOffice?.tipo === "Online" && (
            <Button
              variant="contained"
              className={classes.buttonLink}
              onClick={() => history.push("/atendimento")}
            >
              Atendimento Online
            </Button>
          )}

          {currentTicketOffice?.tipo === "Diario" && (
            <Button
              variant="contained"
              className={classes.buttonLink}
              onClick={() => history.push("/atendimento_diario")}
            >
              Agendamento Orgânico
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}
