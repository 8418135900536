import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#004F9F",
    color: "#FFFFFF",
    flexDirection: "column",
  },
  icon: {
    padding: 10,
  },
  text: {
    fontSize: 32,
    fontWeight: "bold",
    font: "Roboto",
    letterSpacing: 0.3,
  },
  loading: {
    color: "#FFFFFF",
    padding: 10,
  }
}));

export default useStyles;