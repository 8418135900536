import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useServiceLocationContext } from "../../hooks/useServiceLocationContext";
import useStyles from './style';

const parseTime = (timeString: string): number => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return (hours * 3600 + minutes * 60 + seconds) * 1000;
};

export const CallPanelPreviousList = (): JSX.Element | null => {
  const classes = useStyles();
  const { recentTickets } = useServiceLocationContext();

/*   const sortedPasswords = [...recentTickets]
    .sort((a, b) => parseTime(b.horario) - parseTime(a.horario)); */

    if(recentTickets.length === 1) return null 

  return (
    <div>
      <Box className={classes.previous}>
        <Box className={classes.current}>
          <Typography variant="h6" className={classes.label}>Senhas Anteriores</Typography>
        </Box>
        <TableContainer component={Paper} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Senha</TableCell>
                <TableCell className={classes.tableHeaderCell}>Guichê</TableCell>
                <TableCell className={classes.tableHeaderCell}>Horário Chamado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {recentTickets.length === 3 ? 
                recentTickets.slice(0, 3).map((senha, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableRow} component="th" scope="row">{senha.senha}</TableCell>
                    <TableCell className={classes.tableRow}>{senha.guiche}</TableCell>
                    <TableCell className={classes.tableRow}>{senha.horario}</TableCell>
                  </TableRow>
                )) :
                recentTickets.slice(1, 4).map((senha, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableRow} component="th" scope="row">{senha.senha}</TableCell>
                    <TableCell className={classes.tableRow}>{senha.guiche}</TableCell>
                    <TableCell className={classes.tableRow}>{senha.horario}</TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
