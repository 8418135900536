export const getFromLocalStorage = <T>(key: string): T | undefined => {
  const data = localStorage.getItem(key);
  if (data) {
    try {
      return JSON.parse(data) as T;
    } catch (error) {
      console.error(`Erro ao fazer o parsing do valor de ${key} no localStorage:`, error);
      return undefined;
    }
  }
  return undefined;
};
