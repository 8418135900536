import { toast } from 'react-toastify';

type Props = {
  message: string;
  type: 'info' | 'success' | 'warning' | 'error' | 'default';
};

export default function toastMessage({ message, type }: Props) {
  toast(message, {
    type,
  });
}