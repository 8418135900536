import { Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '95%',
    margin: 'auto',
    maxHeight: '100vh', 
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  currentInfo: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '40px 0px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '25px 0px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 0px',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      fontSize: '3rem',
    },
  },
  current: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.5rem',
    },
  },
  noInfo: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2rem',
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '3rem',
    },
  },
  currentNumber: {
    fontSize: '8rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '8rem',
    },
  },
  currentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(1),
    },
  },
  currentLabel: {
    fontSize: '3rem',
    fontWeight: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  previous: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(6),
    },
  },
  table: {
    minWidth: 500,
    tableLayout: 'fixed',
    [theme.breakpoints.down('xs')]: {
      minWidth: 300,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 600,
    },
  },
  tableHeaderCell: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  tableBody: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableRow: {
    color: '#000',
    fontSize: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
}));

export default useStyles;
