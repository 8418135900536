import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: 0,
  },
  tabContent: {
    padding: "10px 24px",
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    margin: 10,
  },
  textHeaderModal: {
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.3,
    padding: "5px 10px",
  },
  header: {
    background: "#FFF",
    border: "1px solid #F7F8FC",
    padding: 10
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#B7B7B7",
  },
}));

export default useStyles;
