import { Box } from '@material-ui/core';
import { CustomerCall } from '../customerCall';
import useStyles from './style';

const ServicePanelInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <CustomerCall />
    </Box>
  );
};

export default ServicePanelInfo;
