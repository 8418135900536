import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { ScheduleInternalResponse } from "../interfaces/agendamentoInterno";
import { apisiseci } from "./api";

export const getAgendamentosInternos = (
  token: string,
  params?: {
    hoje?: boolean;
    anteriores?: boolean;
    status?:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Aguardando Atendimento"
    | "Compareceu"
    | "Não Compareceu";
    in_status?: string;
    out_status?: string;
    page?: number;
    unidade?: string;
    guiche_id?: string;
  }
) =>
  apisiseci.get<ScheduleInternalResponse>(
    "agendamento/agendamentos_internos/",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );

export const updateStatusAgendamentoInternos = (
  token: string,
  id: number,
  status:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu",
  guiche: string,
): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/agendamentos_internos/${id}/?guiche_id=${guiche}`,
    {
      id,
      status,
      guiche,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addObservationInternos = (
  token: string,
  id: string,
  observation: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/observacao_interna/`,
    {
      agendamento: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );


export const checarHorariosInternos = (
  date: Date,
  unity: string,
  serviceID: number
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, "yyyy-MM-dd");
  return apisiseci.get(
    `agendamento/checar_horarios_interno/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`
  );
};

export const agendarInterno = (
  token: string,
  cpf: string,
  userId: number,
  date: Date,
  unity: string,
  serviceID: number,
  hour: string
): Promise<AxiosResponse> => {
  return apisiseci.post(
    `agendamento/agendar_interno/`,
    {
      cpf,
      user: userId,
      unidade: unity,
      servico: serviceID,
      data: format(date, "yyyy-MM-dd"),
      hora: hour,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const searchAgendamentosInternos = (
  token: string,
  params?: {
    data?: string;
    cpf?: string;
    status?:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu";
    page?: number;
  }
) =>
  apisiseci.get<ScheduleInternalResponse>("agendamento/consulta_internos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const incluirDependente = (
  id_agendamento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string
): Promise<AxiosResponse> => {
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post(
    'agendamento/dependente_interno/',
    {
      agendamento: id_agendamento,
      cpf,
      nome,
      data_nascimento: data,
      cpf_solicitante,
      grau_parentesco,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
