import { Box, Button, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef, useImperativeHandle, useState } from "react";
import { healthCheck } from "../../services/websocket";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    outline: "none",
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    maxWidth: 400,
    width: "100%",
  },
  modalActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
  },
}));

const ConfirmRoomCreation = forwardRef((props: any, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loadingCurrentNext, setLoadingCurrentNext] = useState(false);

  async function agendamentosDaVez() {
    setLoadingCurrentNext(true);
    try {
      const isHealthy = await healthCheck();

      if (!isHealthy) {
        throw new Error('Servidor indisponível');
      }
    } catch (error: any) {
      console.error('Erro desconhecido ao criar sala:', error);
    } finally {
      setLoadingCurrentNext(false);
      handleConfirm()
    }
  }

  useImperativeHandle(ref, () => ({
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
  }));

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
    setOpen(false);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalContent}>
        <Typography variant="h6" gutterBottom>
          {props.title || "Confirmação"}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {props.message || "Você tem certeza que deseja continuar?"}
        </Typography>
        <Box className={classes.modalActions}>
          <Button variant="text" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={() => agendamentosDaVez()} disabled={loadingCurrentNext}>
            Confirmar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default ConfirmRoomCreation;
