import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, CircularProgress, FormControl, InputLabel, Link, MenuItem, Select, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isValid } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from "react";
import { MdCheckCircle, MdEvent } from "react-icons/md";
import { useHistory } from 'react-router-dom';
import { checarDisponibilidadeAgendamentos, checarHorariosAtendimento, checarIntervaloAgendamentos, reagendarAtendimento } from "../../../../../services/atendimento";
import dateFormat from '../../../../../utils/dateFormat';
import useStyles from "./style";

interface SavedScheduleProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

interface UnidatyDate {
  setor_unidade: number;
  unidade_slug: string;
  unidade_nome: string;
  datas_disponiveis: string[];
}

function Reschedule({ service, setOpenModal }) {
  const classes = useStyles();
  const history = useHistory();
  const [scheduleServiceUser, setScheduleServiceUser] = useState<string | undefined>();
  const [unitySelected, setUnitySelected] = useState<string>('');
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [scheduleService, setScheduleService] = useState<string[] | any>([]);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [unidades, setUnidades] = useState<UnidatyDate[]>([]);
  const [scheduleSelected, setScheduleSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [savedSchedule, setSavedSchedule] = useState<SavedScheduleProps | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      try {
        const { data } = await checarHorariosAtendimento(date, unity, service?.servico?.id);
        setScheduleService(data);
        setErrorMessage(null);
      } catch (err) {
        if (err.response) {
          setErrorMessage(err.response.data.message);
          setScheduleService(undefined);
        } else if (err.response?.data?.[0]) {
          setErrorMessage(err.response?.data?.[0]);
          setScheduleService(undefined);
        } else {
          setErrorMessage('Algo inesperado ocorreu.');
          setScheduleService(undefined);
        }
      }
    }
  };

  const getStartAndEndOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const data_inicio = new Date(year, month, 1).toISOString().split('T')[0];
    const data_fim = new Date(year, month + 1, 0).toISOString().split('T')[0];
    return { data_inicio, data_fim };
  };

  const handleCheckInterval = async (startDate, endDate, unitySelected, serviceId) => {
    const token = localStorage.getItem('gov_access_token_sso');
    if (!token) {
      console.error('Token not found');
      return;
    }
    const { data_inicio, data_fim } = startDate && endDate ? { data_inicio: startDate, data_fim: endDate } : getStartAndEndOfMonth(new Date());

    try {
      const response = await checarIntervaloAgendamentos(token, serviceId.toString(), 'Online', unitySelected, data_inicio, data_fim);
      setAvailableDates(response?.data);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage('Error submitting data: ' + error);
    }
  };

  useEffect(() => {
    handleDateChange(null);
    if (unitySelected) {
      const { data_inicio, data_fim } = getStartAndEndOfMonth(new Date());
      handleCheckInterval(data_inicio, data_fim, unitySelected, service?.servico.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitySelected]);

  const isDateAvailable = (date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return availableDates.includes(formattedDate);
  };

  const handleMonthChange = async (date, unitySelected, serviceId) => {
    const { data_inicio, data_fim } = getStartAndEndOfMonth(date);
    await handleCheckInterval(data_inicio, data_fim, unitySelected, serviceId);
  };

  const handleListScheduleDates = async (serviceId) => {
    const token = localStorage.getItem('gov_access_token_sso');
    if (!token) {
      console.error('Token not found');
      return;
    }
    try {
      const response = await checarDisponibilidadeAgendamentos(token, serviceId.toString(), 'Online');
      setUnidades(response.data);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage('Error fetching available dates: ' + error);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);

    let tipoLowerCase = '';
    if (typeof service.guiche.tipo === 'string') {
      tipoLowerCase = service.guiche.tipo.toLowerCase();
    } else {
      console.error('service.guiche.tipo is not a string');
    }
    try {
      const token = localStorage.getItem('gov_access_token_sso');
      const { data, status } = await reagendarAtendimento(
        token,
        service.id,
        unitySelected,
        selectedDate,
        scheduleSelected,
        tipoLowerCase,
        service.guiche.ticket
      );
      setScheduleServiceUser(data);
      setSavedSchedule(data);
      setUnitySelected('');
      setScheduleServiceUser(undefined);
      handleDateChange(null);
      setScheduleService(undefined);
      handleListScheduleDates(service?.servico?.id.toString());
      setErrorMessage(null);
      setLoading(true);

     setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      setErrorMessage(err.response?.data?.message || 'Algo inesperado ocorreu.');
      setLoading(false);
    }
  };

  useEffect(() => {
    setUnitySelected('');
    setScheduleServiceUser(undefined);
    setScheduleService(undefined);
    handleListScheduleDates(service?.servico?.id.toString());
  }, []);

  useEffect(() => {
    if (selectedDate && unitySelected) {
      getSchedulers(selectedDate, unitySelected);
    }
  }, [selectedDate, unitySelected]);

  return (
    <>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id="unity-label">Selecione o serviço</InputLabel>
        <Select
          labelId="unity-label"
          value={service.servico.titulo}
          disabled
          onChange={(event) => {
            setUnitySelected(event.target.value as string);
          }}
          label="Selecione a unidade"
        >
          <MenuItem key={service.servico.titulo} value={service.servico.titulo}>
            {service.servico.titulo}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id="unity-label">Selecione a unidade</InputLabel>
        <Select
          labelId="unity-label"
          value={unitySelected}
          onChange={(event) => {
            setUnitySelected(event.target.value as string);
          }}
          label="Selecione a unidade"
        >
          {unidades.map((unity) => (
            <MenuItem key={unity.setor_unidade} value={unity.unidade_slug}>
              {unity.unidade_nome}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl} variant="outlined" disabled={!unitySelected}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
            disableFuture={false}
            minDate={new Date()}
            autoOk
            variant="inline"
            inputVariant="outlined"
            disabled={!unitySelected || typeof scheduleService === 'string' || typeof scheduleServiceUser === 'string' || availableDates.length === 0}
            label="Selecione o dia"
            format="dd/MM/yyyy"
            value={selectedDate || null}
            onChange={(date) => {
              if (date && isValid(date)) {
                handleDateChange(date);
              }
            }}
            shouldDisableDate={(date) => !isDateAvailable(date)}
            onMonthChange={(date) => handleMonthChange(date, unitySelected, service.servico?.id)}
          />
        </MuiPickersUtilsProvider>
      </FormControl>

      <FormControl className={classes.formControl} variant="outlined" disabled={!unitySelected || !selectedDate || typeof scheduleService === 'string'}>
        <InputLabel id="schedule-label">Escolha o horário</InputLabel>
        <Select
          labelId="schedule-label"
          value={scheduleSelected}
          onChange={(event) => setScheduleSelected(event.target.value as string)}
          label="Escolha o horário"
        >
          {scheduleService?.horarios?.map((schedule) => (
            <MenuItem key={schedule} value={schedule}>
              {schedule}
            </MenuItem>
          ))}
        </Select>
        {errorMessage && (
          <Typography color="error" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
      </FormControl>

      <Box className={classes.buttonCloseModal}>
        <Link className={classes.link} onClick={handleClose}>
          fechar
        </Link>
        <Button
         style={{ color: "#FFF", fontSize: 14 }}
         variant="contained"
         color="secondary"
         size="large"
          disabled={!unitySelected || !selectedDate || !scheduleSelected || typeof scheduleService === 'string' || loading}
          onClick={schedulerService}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: 'white' }} />
          ) : (
            'Reagendar'
          )}
        </Button>
      </Box>

      {savedSchedule && (
        <Box className={classes.boxInfoConcluded}>
          <Box className={classes.infoConcluded}>
            <Typography className={classes.titleBox} style={{ margin: 0 }}>Reagendamento feito com sucesso!</Typography>
            <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
          </Box>
          <Box>
            <Typography className={classes.textInfoDateTime}>
              {dateFormat(savedSchedule.data, false)}
              {' '}
              -
              {' '}
              {savedSchedule.hora}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Reschedule;
