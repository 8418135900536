import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { format, isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useCallback, useEffect, useState } from "react";
import { Schedule, ScheduleUser } from "../../../../../interfaces/agendamento";
import { Atendimento } from "../../../../../interfaces/atendimento";
import { ServicoInterno, Unidade } from "../../../../../interfaces/servico";
import {
  agendarInterno,
  checarHorariosInternos,
} from "../../../../../services/agendamento";
import { incluirDependente } from "../../../../../services/agendamentoInterno";
import { sendReply } from "../../../../../services/atendimento";
import { getInterService } from "../../../../../services/servicos";
import { dateFormat } from "../../../../../utils/dateFormat";
import useStyles from "./style";


interface Props {
  setOpenModal: (openModal: boolean) => void;
  attendence: undefined | Atendimento;
}

export default function AttendanceNewScheduleInternal({
  setOpenModal,
  attendence,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [interServices, setInterServices] = useState<
    undefined | ServicoInterno[]
  >();

  const [sectorUnits, setSectorUnits] = useState<undefined | Unidade[]>();
  const [attendenceSelected, setScheduleSelected] = useState<string>("");

  const [serviceSelected, setServiceSelected] = useState<
    undefined | ServicoInterno
  >();

  const [selectedDate, handleDateChange] = useState<Date>(new Date());
  const [unitySelected, setUnitySelected] = useState<string>("");

  const [attendenceService, setScheduleService] = useState<
    string | Schedule | undefined
  >();
  const [attendenceServiceUser, setScheduleServiceUser] = useState<
    ScheduleUser | string | undefined
  >();


  const disableWeekends = (date: MaterialUiPickersDate): boolean =>
    date
      ? date.getDay() === 0 ||
      date.getDay() === 6 ||
      format(date, "yyyy-MM-dd") === "2021-02-16"
      : false;
  const handleClose = () => {
    setOpenModal(false);
  };


  const interServiceToService = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await getInterService(
        (window as any)._env_.REACT_APP_API_SISECI_KEY ?? "",
        id
      );
      if (data) {
        setInterServices(data);
      }
    } catch (error) {
      console.log("Erro", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleChangeService = (event) => {
    setSectorUnits(undefined);

    let serviceFilter = interServices.filter(
      ({ id }) => id === parseInt(event.target.value)
    );

    if (serviceFilter[0]) {
      const unidades = serviceFilter[0]?.unidade.map(({ nome, slug_unidade }) => ({
        nome,
        slug_unidade,
      }));

      setSectorUnits(unidades);
      setServiceSelected(serviceFilter[0]);
    }
  };


  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date) && serviceSelected) {
      try {
        const { data } = await checarHorariosInternos(
          date,
          unity,
          serviceSelected.id
        );
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else {
          setScheduleService("Algo inesperado ocorreu");
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const handleSchedulerService = async () => {
    setLoading(true);
    const token: string | null = localStorage.getItem(
      "gov_access_token_sso"
    );
    if (token && attendence && serviceSelected) {
      try {
        const { data } = await agendarInterno(
          token,
          attendence.user.username,
          attendence.user.id,
          selectedDate,
          unitySelected,
          serviceSelected.id,
          attendenceSelected
        );
        if (data) {
          await sendReply(
            token,
            attendence.id,
            `
              <h4>Novo Agendamento</h4>
              <strong>SERVIÇO: </strong> ${data.servico_titulo}<br/>
              <strong>CPF: </strong> ${data.cpf}<br/>
              <strong>Data: </strong> ${dateFormat(data.data)}<br/>
              <strong>Hora: </strong> ${data.hora}<br/>
          `,
            false
          );

          if (attendence.para_dependente && attendence?.dependentes) {
            await incluirDependente(
              data.id,
              attendence.user.username,
              attendence.dependentes.nome,
              new Date(attendence.dependentes.data_nascimento),
              attendence.dependentes.cpf,
              attendence.dependentes.grau_parentesco,
              token
            );
          }
          setOpenModal(false);
        }
      } catch (err) {
        if (err.response.data.message) {
          setScheduleServiceUser(err.response.data.message);
        } else {
          setScheduleServiceUser("Algo inesperado ocorreu.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const removeEmptyUnits = (service: ServicoInterno) => {
    return service?.unidade?.length !== 0
  }

  useEffect(() => {
    if (attendence?.servico && attendence.servico.id) {
      interServiceToService(attendence.servico.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendence]);

  return (
    <>
      {attendence && (
        <div>
          {interServices && interServices.length && (
            <>
              <div id="input-servico">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione o serviço
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={handleChangeService}
                    label="Selecione o serviço"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {interServices &&
                      interServices.filter(removeEmptyUnits).map((service) => (
                        <MenuItem
                          key={service.slug}
                          className={classes.menuItem}
                          value={service.id}
                        >
                          {service.titulo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div id="input-unidade">
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione a unidade
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(event) => {
                      setUnitySelected(event.target.value as string);
                      getSchedulers(selectedDate, event.target.value as string);
                    }}
                    disabled={!sectorUnits}
                    label="Selecione a unidade"
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {sectorUnits &&
                      sectorUnits.map((unit) => (
                        <MenuItem
                          key={unit.slug_unidade}
                          className={classes.menuItem}
                          value={unit.slug_unidade}
                        >
                          {unit.nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div id="input-data">
                <FormControl
                  classes={{ root: classes.root }}
                  error={
                    typeof attendenceService === "string" ||
                    typeof attendenceServiceUser === "string"
                  }
                  disabled={!unitySelected && !sectorUnits}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture={false}
                      error={
                        typeof attendenceService === "string" ||
                        typeof attendenceServiceUser === "string"
                      }
                      shouldDisableDate={disableWeekends}
                      minDateMessage="Você não pode agendar em dias anteriores"
                      invalidDateMessage="A data informada é inválida"
                      minDate={new Date()}
                      InputProps={{
                        className: classes.menuItem,
                        classes: { adornedEnd: classes.adornedEnd },
                      }}
                      autoOk
                      disabled={(loading || (!unitySelected))}
                      variant="inline"
                      inputVariant="outlined"
                      label="Selecione o dia"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: "end", color: "#000" }}
                      onChange={(date) => {
                        if (date && isValid(date)) {
                          handleDateChange(date);
                          if (unitySelected) {
                            getSchedulers(date, unitySelected);
                          }
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {typeof attendenceService === "string" && (
                    <FormHelperText>{attendenceService}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div id="input-horario">
                <FormControl
                  classes={{ root: classes.root }}
                  disabled={
                    !unitySelected ||
                    !selectedDate ||
                    typeof attendenceService === "string"
                  }
                  variant="outlined"
                  className={classes.formControl}
                  error={typeof attendenceServiceUser === "string"}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Escolha o horário
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    classes={{
                      select: classes.menuItem,
                    }}
                    onChange={(event): void => {
                      setScheduleSelected(event.target.value as string);
                    }}
                    label="Escolha o horário"
                  >
                    {typeof attendenceService !== "string" &&
                      attendenceService?.horarios?.map((attendence) => (
                        <MenuItem
                          key={attendence}
                          className={classes.menuItem}
                          value={attendence}
                        >
                          {attendence}
                        </MenuItem>
                      ))}
                  </Select>
                  {typeof attendenceServiceUser === "string" && (
                    <FormHelperText>{attendenceServiceUser}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <Divider light />
              <Box className={classes.buttonCloseModal}>
                {!loading && (
                  <Link className={classes.link} onClick={handleClose}>
                    fechar
                  </Link>
                )}
                <Button
                  style={{ color: "#FFF", fontSize: 14 }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleSchedulerService}
                  disabled={
                    loading ||
                    !unitySelected ||
                    !selectedDate ||
                    typeof attendenceService === "string" ||
                    typeof attendenceService === "undefined" ||
                    !attendenceSelected
                  }
                >
                  {!loading ? "AGENDAR" : <CircularProgress />}
                </Button>
              </Box>
            </>
          )}
        </div>
      )}
    </>
  );
}
