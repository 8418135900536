import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    background: "#FFFFFF",
    maxWidth: 900,
    margin: "auto",
    padding: 40,
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
  },
  textTitleHome: {
    fontFamily: "Mulish",
    fontSize: 32,
    fontStyle: "normal",
    letterSpacing: 0.3,
    fontWeight: 700,
    color: "#373F41",
    textAlign: "center",
    flex: 1,
    marginBottom: 50,
  },
  imgHome: {
    width: "100%",
    maxWidth: 400,
    flex: 1,
  },
  buttonLinkOnline: {
    background: "#F2994A",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    color: "#FFFFFF",
    width: 220,
    padding: 10,
    '&:hover': {
      background: "#F2994A",
    },
    marginTop: 50,
    flex: 1,
  },
  buttonLink: {
    background: "#004F9F",
    color: "#FFFFFF",
    width: 220,
    padding: 10,
    '&:hover': {
      background: "#004F9F",
    },
    marginTop: 50,
    flex: 1,
  },
}));

export default useStyles;